<template>
  <div>
    <van-row type="flex" justify="center">
      <div class="van-hairline-top-bottom imgColor">
        <van-image class="title-center" :src="require('../img/png1.png')" />
      </div>
    </van-row>

    <van-row type="flex" justify="center" class="parent30">
      <van-col>
        <van-cell-group>
          <van-field
            v-model="shipmentno"
            readonly
            center
            clearable
            :label="$t('job_no')"
            label-align="left"
            label-width="120px"
            class="my-field"
            label-class="my-label"
            type="text"
          >
          </van-field>
        </van-cell-group>
      </van-col>
    </van-row>

    <van-row type="flex" justify="center" class="succTxt">
      <van-col>
        <span class="successText">
          {{ $t("successText") }}
        </span>
      </van-col>
    </van-row>

    <van-row type="flex" gutter="20" class="parentbtn" justify="center">
      <van-col span="12"
        ><van-button
          round
          block
          type="info"
          native-type="button"
          @click="onTopPageClick()"
          >{{ $t("top_page") }}</van-button
        ></van-col
      >
    </van-row>

    <van-row type="flex" justify="center" class="parent130">
      <div
        style="
          text-align: center;
          border-top: 2px solid #ff0000;
          padding-top: 10px;
          bottom: 0px;
          width: 100%;
        "
      >
        <span
          >Copyright © HANKYU HANSHIN <br />EXPRESS Co., Ltd. All Right
          Reserved.</span
        >
      </div>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";

export default {
  name: "success",
  data() {
    return {
      shipmentno: "",
      sucUrlErrTxt: true,
      company: "",
      branch: "",
    };
  },
  created() {
    this.shipmentno = this.$route.params.shipmentno;
  },
  methods: {
    onTopPageClick() {
      this.$router.push({
        name: "indexView",
        params: {
          language: this.$route.params.language,
          sucUrlErrTxt: this.sucUrlErrTxt,
          company: this.company,
          branch: this.branch,
        },
      });
    },
  },
};
</script>

<style>
.title-center {
  display: block;
  margin: 0 auto;
}
.imgColor {
  border-top: 2px solid #ff0000;
  border-bottom: 2px solid #cbcbcb;
  width: 100%;
  align-items: center;
}
.parent30 {
  margin-top: 30px;
}
.parent130 {
  margin-top: 130px;
}
.link {
  text-decoration: underline;
}
.parentbtn {
  margin-top: 100px;
}
.succTxt {
  margin-top: 10px;
  text-align: center;
}
.my-field {
  width: 100%;
  background-color: #f0f0f0 !important;
  border-radius: 6px;
  text-align: right;
  align-items: center;
  padding: 12px;
}
.successText {
  display: inline-block;
  padding-left: 10px;
  text-align: center;
  font-size: 22px;
  width: 100%;
  margin-top: 50px;
}
.my-label {
  color: #888888;
  font-size: 16px;
}
</style>
