import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'font-awesome/css/font-awesome.min.css';
import VueI18n from 'vue-i18n'
import enLocale from 'vant/es/locale/lang/en-US'
import jaLocale from 'vant/es/locale/lang/ja-JP'
import zhLocale from 'vant/es/locale/lang/zh-CN'

Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(VueI18n);

const messages = {
  'en': {
    job_no: "Shipment No",
    upload_time: "Delivery Actual Time",
    Step1: "Step1：scan the QR code.",
    Step2: "Step2：Key in delivered date / time.",
    Step3: "Step3：Take photo of signed D/O or select photo to upload.",
    cancel: "Cancel",
    confirm: "Confirm",
    next: "Next",
    back: "Back",
    upload: "Submit",
    top_page: "Top page",
    retry: "Retry",
    successText: "POD uploaded successfully.",
    errorText1: "Failed to upload.",
    QRcodeError: "Please scan QR code again",
    loading: "loading…",
    msg1: "Only Image files can be uploaded",
    msg3:"The size of {} file is over 10MB, cannot be uploaded",
    msg4:"Actual delivery date cannot be in the future",
  },
  'ja': {
    job_no: "Shipment番号",
    upload_time: "配達時刻",
    Step1: "ステップ１：QRコードをスキャンします",
    Step2: "ステップ２：配達時刻を指定します",
    Step3: "ステップ３：写真を撮り画像を選択します",
    cancel: "キャンセル",
    confirm: "確　定",
    next: "次　へ",
    back: "戻　る",
    upload: "送　信",
    top_page: "トップページへ戻る",
    retry: "再送信",
    successText: "正常に登録されました。",
    errorText1: "アップロードが失敗しました。",
    QRcodeError: "QRコードをもう一度読み取ってください",
    loading: "転　送　中...",
    msg1: "画像ファイルしかアップロードできません",
    msg3:"{}番目のファイルが10MBを超えているため、アップロードできません",
    msg4:"配達時刻は未来日を指定できません",
  },
  'zh':{
    job_no: "Shipment号码",
    upload_time: "实际送达时间",
    Step1: "第一步：扫描二维码",
    Step2: "第二步：指定送达时间",
    Step3: "第三步：上传送达证明照片",
    cancel: "取　消",
    confirm: "确　认",
    next: "下一步",
    back: "上一步",
    upload: "提　交",
    top_page: "返回首页",
    retry: "重　试",
    successText: "上传成功。",
    errorText1: "上传失败。",
    QRcodeError: "请重新扫描二维码",
    loading: "上　传　中...",
    msg1: "仅支持上传图片文件",
    msg3:"第{}张图片的大小超过10MB，无法上传",
    msg4:"实际送达时间不可以指定为未来时间",
  },
  'zh-TW':{
    job_no: "Shipment號碼",
    upload_time: "實際送達時間",
    Step1: "第一步: 掃描二維條碼",
    Step2: "第二步: 指定送達時間",
    Step3: "第三步: 上傳簽收單",
    cancel: "取　消",
    confirm: "確　認",
    next: "下一步",
    back: "上一步",
    upload: "送　出",
    top_page: "返回首頁",
    retry: "重新傳送",
    successText: "上傳成功。",
    errorText1: "上傳失敗。",
    QRcodeError: "請重新掃描二維條碼",
    loading: "上　傳　中...",
    msg1: "僅支援圖像檔上傳",
    msg3:"第{}張圖片的大小超過10MB, 無法上傳  ",
    msg4:"實際送達時間不可以指定為未來時間",
  }
}
const defaultLanguages = navigator.language;
let locale = "";
if (defaultLanguages === "zh-TW") {
  locale = "zh-TW";
} else if (
  defaultLanguages === "zh-HK" ||
  defaultLanguages === "zh-MO" ||
  defaultLanguages === "zh-SG"
) {
  locale = "en";
}else{
  const defaultLanguage = defaultLanguages.substring(0, 2);
  if (defaultLanguage !== 'ja' && defaultLanguage !== 'zh') {
    locale = 'en';
  }
}
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
