import Vue from 'vue';
import VueRouter from 'vue-router';
import Init from '@/views/Init.vue';
import IndexView from '@/views/indexView.vue';
import Success from '@/views/Success.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/init'
  },
  {
    name: 'init',
    path: '/init/:shipmentno?',
    component: Init,
  },
  {
    path: '/indexview',
    name: 'indexView',
    component: IndexView,
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
  },
  {
    path: '*',
    name: 'init',
    component: Init
  }
];
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

export default new VueRouter({
  mode: 'history',
  routes: routes
});
