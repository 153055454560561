<template>
  <div></div>
</template>
<script>
export default {
  name: "init",
  mounted() {
    // アドレスバーパラメータの取得
    var shipmentno = this.$route.query.shipmentno;
    var company = this.$route.query.company;
    if (!shipmentno) {
      shipmentno = "";
    }
    if (!company) {
      company = "";
    }

    this.$router.push({
      name: "indexView",
      params: {
        param: shipmentno,
        company: company,
      },
    });
  },
};
</script>
