<template>
  <div>
    <div class="overlay" v-if="loading"></div>
    <div class="scan" v-if="isShow">
      <div class="video-container">
        <video class="video" id="video-1"></video>
      </div>
      <div class="parentbtn">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          @click="closescan()"
        >
          <span class="placeholder"></span>
          <span>{{ $t("cancel") }}</span>
          <span class="placeholder"></span>
        </van-button>
      </div>
      <!-- <div>
        <input type="file" accept="image/*" @change="decodeQRCodeFromImage" />
      </div> -->
    </div>
    <div v-show="all_show_flg">
      <van-row type="flex" justify="center">
        <div class="imgColor">
          <van-image class="title-center" :src="require('../img/png1.png')" />
        </div>
      </van-row>
      <van-row>
        <div style="float: right" v-if="!failModel">
          <van-dropdown-menu class="my-dropdown-menu">
            <van-dropdown-item
              v-if="!previewModel"
              v-model="selectedLanguage"
              :options="languages"
              @change="changeLanguage"
              :style="{ width: '100%' }"
            />
          </van-dropdown-menu>
        </div>
      </van-row>
      <van-row type="flex" justify="center" class="parent30">
        <van-col>
          <van-cell-group>
            <van-field
              v-model="shipmentno"
              readonly
              center
              clearable
              :label="$t('job_no')"
              label-align="left"
              label-width="120px"
              class="my-field"
              label-class="my-label"
              type="text"
            >
              <template #button v-if="!previewModel">
                <van-icon name="qr" size="30" @click="scanCode()" />
              </template>
            </van-field>
          </van-cell-group>
        </van-col>
      </van-row>
      <van-row type="flex" justify="center" class="height60">
        <van-col span="18" style="color: red" v-if="!previewModel">{{
          $t("Step1")
        }}</van-col>
      </van-row>

      <van-row type="flex" justify="center" v-if="failModel" class="failTxt">
        <van-col span="18" style="color: red">{{ $t("errorText1") }}</van-col>
      </van-row>

      <van-row type="flex" justify="center" class="parent15" v-if="!failModel">
        <van-col>
          <van-cell-group>
            <van-field
              readonly
              clickable
              v-model="UploadDate"
              :label="$t('upload_time')"
              label-align="left"
              type="text"
              label-width="120px"
              class="my-field"
              label-class="my-label"
              :after-read="hidNext"
            >
              <template #button v-if="!previewModel">
                <van-icon name="clock-o" size="30" @click="isJobNo" />
              </template>
            </van-field>
            <van-popup v-model="showPicker" position="bottom">
              <van-datetime-picker
                :confirm-button-text="$t('confirm')"
                :cancel-button-text="$t('cancel')"
                v-model="currentDate"
                type="datetime"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
          </van-cell-group>
        </van-col>
      </van-row>
      <van-row type="flex" justify="center">
        <van-col span="18" style="color: red" v-if="!previewModel">{{
          $t("Step2")
        }}</van-col>
      </van-row>
      <van-row type="flex" justify="center" class="parent15" v-if="!failModel">
        <van-col class="imgFormat">
          <van-uploader
            :deletable="!previewModel"
            v-model="fileList"
            :preview-options="{ onChange: tranformImg }"
            @click-preview="tranformImg"
            multiple
            :limit="1"
            :max-count="5"
            preview-size="120"
            :before-read="beforeRead"
            :after-read="hidNext"
            :before-delete="beforeDelete"
            :disabled="isJobNm"
            ><van-image
              :src="require('../img/png2.png')"
              v-show="!previewModel"
            />
            <template #preview-cover="{ file }">
              <div
                v-show="!previewModel && file.type != 'application/pdf'"
                class="preview-cover van-ellipsis"
                @click.stop="rotateImage(file, $event)"
              >
                <img src="../img/png3.png" alt="" />
              </div>
            </template>
          </van-uploader>
        </van-col>
      </van-row>
      <van-row type="flex" justify="center">
        <van-col span="18" style="color: red" v-if="!previewModel">{{
          $t("Step3")
        }}</van-col>
      </van-row>
      <van-row type="flex" gutter="30" class="parentbtn" justify="center">
        <van-col span="10"
          ><van-button
            v-if="nextBut"
            round
            block
            type="info"
            native-type="button"
            :disabled="isClick"
            @click="onNextClick()"
            >{{ $t("next") }}</van-button
          ></van-col
        >
      </van-row>
      <van-row type="flex" gutter="30" justify="center" v-if="!failModel">
        <van-col span="10"
          ><van-button
            v-if="previewModel"
            round
            block
            type="info"
            native-type="submit"
            @click="onBackClick()"
            >{{ $t("back") }}</van-button
          ></van-col
        >
        <van-col span="10"
          ><van-button
            v-if="previewModel"
            :loading="loading"
            loading-type="spinner"
            :loading-text="$t('loading')"
            round
            block
            type="info"
            native-type="submit"
            @click="onTransmitCheck()"
            >{{ $t("upload") }}</van-button
          ></van-col
        >
      </van-row>
      <van-row type="flex" gutter="30" class="parentbtn" justify="center">
        <van-col span="10"
          ><van-button
            v-if="failModel"
            round
            block
            type="info"
            native-type="button"
            @click="onTryAgainClick()"
            >{{ $t("retry") }}</van-button
          ></van-col
        >
      </van-row>
      <van-row type="flex" justify="center" class="parent100">
        <div
          style="
            text-align: center;
            border-top: 2px solid #ff0000;
            padding-top: 10px;
            bottom: 0px;
            width: 100%;
          "
        >
          <span
            >Copyright © HANKYU HANSHIN <br />EXPRESS Co., Ltd. All Right
            Reserved.</span
          >
        </div>
      </van-row>
    </div>
    <div>
      <van-notify
        v-model="showErrorPopup"
        type="danger"
        :duration="3000"
        style="justify-content: left; text-align: left"
      >
        <span>{{ errorMessage }}</span>
      </van-notify>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { Uploader } from "vant";
import {
  BrowserMultiFormatReader,
  ChecksumException,
  FormatException,
} from "@zxing/library";
import { Loading } from "vant";
import { Popup } from "vant";
Vue.use(Uploader);
Vue.use(Loading);
Vue.use(Popup);
import { DropdownMenu, DropdownItem } from "vant";
Vue.use(DropdownMenu).use(DropdownItem);
import { getConfigVariables } from "../config/config.js";
export default {
  name: "indexView",
  data() {
    return {
      shipmentno: "",
      company: "",
      fileList: [],
      formData: null, // formDataオブジェクトの初期化

      all_show_flg: true,
      isShow: false,
      transmit: false,
      previewModel: false,
      deletable: true,
      nextBut: true,
      failModel: false,
      isClick: true,
      isJobNm: false,
      UrlErrTxt: false,

      codeReader: new BrowserMultiFormatReader(),
      videoInputDevices: [],
      currentVideoInputDevice: {},
      decodeResult: undefined,

      loading: false,

      showPicker: false,
      UploadDate: "",
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),

      showErrorPopup: false,
      errorMessage: "",

      selectedLanguage: "ja",
      languages: [
        { text: "日本語", value: "ja" },
        { text: "English", value: "en" },
        { text: "简体中文", value: "zh" },
        { text: "繁體中文", value: "zh-TW" },
      ],
    };
  },
  created() {
    this.defaultLanguage();
    this.getNowTime();
    this.getSucLanguage();
    this.imgCenter();
    this.changeLanguage();
    this.getSucUrlErr();
    // init転送のパラメータを取得するには
    this.shipmentno = this.$route.params.param;
    this.company = this.$route.params.company;
    this.UrlTxtChack();
    this.isPicClick();
  },
  methods: {
    getSucLanguage() {
      if (this.$route.params.language) {
        this.selectedLanguage = this.$route.params.language;
      }
    },
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
    },
    defaultLanguage() {
      const defaultLanguages = navigator.language;
      if (defaultLanguages === "zh-TW") {
        this.selectedLanguage = "zh-TW";
      } else if (
        defaultLanguages === "zh-HK" ||
        defaultLanguages === "zh-MO" ||
        defaultLanguages === "zh-SG"
      ) {
        this.selectedLanguage = "en";
      } else {
        const defaultLanguage = defaultLanguages.substring(0, 2);
        this.selectedLanguage = defaultLanguage;

        if (defaultLanguage !== "ja" && defaultLanguage !== "zh") {
          this.selectedLanguage = "en";
        }
      }
    },
    scanCode() {
      this.handleCameraPermission();
    },
    getSucUrlErr() {
      if (this.$route.params.sucUrlErrTxt) {
        this.UrlErrTxt = this.$route.params.sucUrlErrTxt;
      }
    },
    jobnoFlg() {
      var message = this.$t("QRcodeError");
      const shipmentno = this.shipmentno;
      const mode = ["A", "S", "R", "O"];
      const countryCode = [
        "3",
        "4",
        "5",
        "A",
        "B",
        "C",
        "D",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        "M",
        "N",
        "P",
        "Q",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
      ];
      var jobChk = false;
      if (shipmentno && shipmentno.length == 13) {
        jobChk = true;
      } else {
        jobChk = false;
        this.shipmentno = "";
        return jobChk;
      }
      if (shipmentno.slice(0, 3) === "SHH") {
        jobChk = true;
      } else {
        jobChk = false;
        this.shipmentno = "";
        return jobChk;
      }
      if (mode.includes(shipmentno.charAt(3))) {
        jobChk = true;
      } else {
        jobChk = false;
        this.shipmentno = "";
        return jobChk;
      }
      if (countryCode.includes(shipmentno.charAt(4))) {
        jobChk = true;
      } else {
        jobChk = false;
        this.shipmentno = "";
        return jobChk;
      }
      if (/^\d+$/.test(shipmentno.slice(5, 13))) {
        jobChk = true;
      } else {
        jobChk = false;
        this.shipmentno = "";
        return jobChk;
      }
      return jobChk;
    },
    rotateImage(file, event) {
      let imgContainer = event.target.closest(".van-uploader__preview-image");
      if (imgContainer) {
        let img = imgContainer.querySelector(".van-image__img");
        if (img) {
          let imgDegree = img.getAttribute("degree") || 0;
          let addDegree =
            parseInt(imgDegree) + 90 > 360
              ? (parseInt(imgDegree) + 90) % 360
              : parseInt(imgDegree) + 90;
          img.style.transform = "rotate(" + addDegree + "deg)";
          img.setAttribute("degree", addDegree);
          Object.defineProperty(file, "degree", {
            value: addDegree,
            writable: true, // 属性の値を上書きできるかどうか
            enumerable: true, // 属性を列挙できるかどうか
            configurable: true, // 属性を削除できるかどうか
          });
        }
      }
    },
    UrlTxtChack() {
      if (
        (this.jobnoFlg() == false || this.company.length != 3) &&
        this.UrlErrTxt == false
      ) {
        this.shipmentno = "";
        var message = this.$t("QRcodeError");
        this.errorMessageChk(message);
        return false;
      }
    },
    tranformImg() {
      this.$nextTick(() => {
        let imagePage = document.querySelector(".van-image-preview__index"); // 画像ページ表示のノードを取得する
        let imagePar = document.querySelector(".van-swipe__track"); // すべてのフォトノードの親//スペースがあるので現在のページ数を取得-1

        let index =
          imagePage.innerHTML.slice(0, imagePage.innerHTML.indexOf("/") - 1) -
          1; //現在の写真の下付き文字を取得

        let degree = this.fileList[index]["file"]["degree"] || 0; // ページ数と配列の下標が一致しないので-1
        if (
          imagePar.childNodes[index] &&
          imagePar.childNodes[index].childNodes[0]
        ) {
          imagePar.childNodes[
            index
          ].childNodes[0].style.transform = `rotate(${degree}deg)`;
        }
      });
    },

    errorMessageChk(message) {
      this.errorMessage = message;
      this.showErrorPopup = true;
      setTimeout(() => {
        this.showErrorPopup = false;
      }, 3000);
    },
    isPicClick() {
      this.isJobNm = !this.jobnoFlg();
    },
    isJobNo() {
      this.showPicker = this.jobnoFlg();
    },
    beforeRead(files) {
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const isJPG = file.type === "image/jpeg";
          const isPNG = file.type === "image/png";
          const isBMP = file.name.endsWith(".bmp");
          const isPDF = file.type === "application/pdf";
          const isGIFF = file.name.endsWith(".giff");
          const isTIF = file.type === "image/tif";
          const isGIF = file.type === "image/gif";
          const isTIFF = file.type === "image/tiff";
          const isValid =
            isJPG ||
            isPNG ||
            isBMP ||
            isPDF ||
            isGIF;
          if (!isValid) {
            this.errorMessage = this.$t("msg1");
            this.showErrorPopup = true;
            setTimeout(() => {
              this.showErrorPopup = false;
            }, 3000);
            return false;
          }
        }
      } else {
        const isJPG = files.type === "image/jpeg";
        const isPNG = files.type === "image/png";
        const isBMP = files.name.endsWith(".bmp");
        const isPDF = files.type === "application/pdf";
        const isGIFF = files.name.endsWith(".giff");
        const isTIF = files.type === "image/tif";
        const isGIF = files.type === "image/gif";
        const isTIFF = files.type === "image/tiff";
        const isValid =
          isJPG ||
          isPNG ||
          isBMP ||
          isPDF ||
          isGIF;
        if (!isValid) {
          this.errorMessage = this.$t("msg1");
          this.showErrorPopup = true;
          setTimeout(() => {
            this.showErrorPopup = false;
          }, 3000);
          return false;
        }
      }
      this.errorMessage = "";
      this.showErrorPopup = false;
      return true;
    },
    hidNext(file) {
      if (
        this.shipmentno &&
        this.shipmentno.length > 0 &&
        this.UploadDate &&
        this.UploadDate.length > 0 &&
        this.fileList.length > 0
      ) {
        this.nextBut = true;
        this.isClick = false;
      } else {
        this.isClick = true;
      }
    },
    beforeDelete() {
      if (this.fileList.length === 1) {
        this.nextBut = true;
        this.isClick = true;
      }
      return true;
    },
    onNextClick() {
      var now = new Date();
      var displayedTime = new Date(this.UploadDate);
      if (displayedTime > now) {
        this.errorMessage = this.$t("msg4");
        this.showErrorPopup = true;
        setTimeout(() => {
          this.showErrorPopup = false;
        }, 3000);
        return false;
      }

      const oversizedPhotos = [];
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].file.size / 1024 / 1024 > 10) {
          if (this.selectedLanguage == "en") {
            var message = oversizedPhotos.push(
              this.$t("msg3").replace("{}", this.getOrdinalSuffix(i + 1))
            );
          } else {
            var message = oversizedPhotos.push(
              this.$t("msg3").replace("{}", i + 1)
            );
          }
          this.errorMessage = message;
          this.showErrorPopup = true;
        }
      }
      if (oversizedPhotos.length > 0) {
        this.errorMessage = oversizedPhotos.join("\n");
        this.showErrorPopup = true;
        setTimeout(() => {
          this.showErrorPopup = false;
        }, 3000);
        return;
      }
      this.nextBut = false;
      this.previewModel = true;
      const element = document.querySelector(".van-dropdown-menu__bar");
      element.style.boxShadow = "none";
      const high = document.querySelector(".height60");
      high.style.height = "0px";
    },
    getOrdinalSuffix(number) {
      const suffixes = ["th", "st", "nd", "rd"];
      const suffixIndex =
        number % 10 < 4 && number % 100 < 14 ? number % 10 : 0;
      return number + suffixes[suffixIndex];
    },
    onBackClick() {
      this.nextBut = true;
      this.previewModel = false;
      // this.imgCenter();
      const element = document.querySelector(".van-dropdown-menu__bar");
      element.style.boxShadow = "0 2px 12px rgba(100,101,102,.12)";
      const high = document.querySelector(".height60");
      high.style.height = "20px";
    },
    requestCameraPermission() {
      return navigator.mediaDevices.getUserMedia({ video: true });
    },
    handleCameraPermission() {
      this.requestCameraPermission()
        .then((stream) => {
          // カメラストリームの取得に成功しました
          this.isShow = true;
          const videoTrack = stream.getVideoTracks()[0];
          videoTrack.stop();
          this.openScan();
        })
        .catch((error) => {
          // カメラ権限の取得に失敗しました
          this.isShow = false;
        });
    },
    async openScan() {
      const _this = this;
      this.all_show_flg = false;
      _this.codeReader
        .listVideoInputDevices()
        .then((videoInputDevices) => {
          if (videoInputDevices && videoInputDevices.length) {
            if (videoInputDevices.length > 1) {
              videoInputDevices.reverse();
            } // フロントカメラを起動しない
            _this.videoInputDevices = videoInputDevices;
            _this.currentVideoInputDevice = videoInputDevices[0];
          }
        })
        .catch((err) => {
          _this.isShow = false;
          console.error(err);
        });
    },
    async decodeQRCodeFromImage(e) {
      const _this = this;
      const file = e.target.files[0];
      const img = document.createElement("img");
      img.setAttribute("id", "myimge");
      const reader = new FileReader();
      reader.onload = () => {
        img.src = reader.result;
        _this.codeReader
          .decodeFromImage(img)
          .then((result) => {
            //_this.jobno = result.text;
            _this.codeReader.reset(); // カメラを閉じる
            _this.isShow = false;
          })
          .catch((err) => {
            console.error(err);
          });
      };
      reader.readAsDataURL(file);
    },
    decodeFromInputVideo() {
      const _this = this;
      _this.codeReader.reset();
      // 複数回
      _this.codeReader.decodeFromVideoDevice(
        _this.currentVideoInputDevice.deviceId,
        "video-1",
        (result, err) => {
          if (result) {
            _this.decodeResult = result;
          }
          if (err) {
            if (err instanceof ChecksumException) {
              console.log(
                "A code was found, but it's read value was not valid."
              );
            }
            if (err instanceof FormatException) {
              console.log("A code was found, but it was in a invalid format.");
            }
          }
        }
      );
    },
    successDecode() {
      const _this = this;
      var message = this.$t("QRcodeError");
      const str = _this.decodeResult.text;
      const url = new URL(str);
      _this.shipmentno = url.searchParams.get("shipmentno");
      _this.company = url.searchParams.get("company");
      if (url.searchParams.get("shipmentno")) {
        _this.shipmentno = url.searchParams.get("shipmentno");
      } else {
        _this.shipmentno = "";
        _this.errorMessageChk(message);
      }
      if (
        url.searchParams.get("company") &&
        url.searchParams.get("company").length == 3
      ) {
        _this.company = url.searchParams.get("company");
      } else {
        _this.company = "";
        _this.errorMessageChk(message);
      }
      _this.codeReader.reset(); // カメラを閉じる
      _this.isShow = false;
      _this.all_show_flg = true;
      _this.getNowTime();
      _this.fileList = [];
      _this.isPicClick();
      _this.UrlTxtChack();
      _this.hidNext();
    },
    closescan() {
      const _this = this;
      _this.codeReader.reset(); // カメラを閉じる
      _this.isShow = false;
      _this.all_show_flg = true;
      _this.isPicClick();
      // this.UrlTxtChack();
    },
    getNowTime() {
      var now = new Date();
      var year = now.getFullYear(); // 取得年
      var month = now.getMonth(); // 取得月
      var date = now.getDate(); // 取得日
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      let hour = now.getHours();
      let minute = now.getMinutes();
      hour = hour.toString().padStart(2, "0");
      minute = minute.toString().padStart(2, "0");
      //const second = item.getSeconds();
      this.UploadDate =
        year + "/" + month + "/" + date + " " + hour + ":" + minute;
    },
    onConfirm(item) {
      var year = item.getFullYear(); // 取得年
      var month = item.getMonth(); // 取得月
      var date = item.getDate(); // 取得日
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      let hour = item.getHours();
      let minute = item.getMinutes();
      hour = hour.toString().padStart(2, "0");
      minute = minute.toString().padStart(2, "0");

      //const second = item.getSeconds();
      this.UploadDate =
        year + "/" + month + "/" + date + " " + hour + ":" + minute;
      this.showPicker = false;
    },
    onTransmit() {
      const [apiUrl] = getConfigVariables();
      // loading op
      this.loading = true;
      const formData = new FormData();
      formData.append("ShipmentNo", this.shipmentno);
      formData.append("UploadDate", this.UploadDate);
      formData.append("Company", this.company);
      const fileDegreeList = [];
      this.fileList.forEach((file) => {
        formData.append("ImgFile", file.file); // fileListの画像をformDataに1つずつ追加する
        const fileDegreeObj = {
          degree: file.file["degree"],
        };
        fileDegreeList.push(fileDegreeObj);
      });
      const fileDegreeListString = JSON.stringify(fileDegreeList);
      formData.append("fileDegreeList", fileDegreeListString);
      this.formData = formData;
      axios
        .post(apiUrl, this.formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data == "NeedRefresh") {
            window.location.reload();
            return;
          } else if(response.data == "uploadErr") {
            // 処理エラー
            this.loading = false;
            this.failModel = true;
          } else {
            // loading close
            this.loading = false;
            this.$router.push({
              name: "success",
              params: {
                shipmentno: this.shipmentno,
                language: this.$i18n.locale,
              },
            });
          }
        })
        .catch((error) => {
          // 処理エラー
          this.loading = false;
          console.log(error);
          this.failModel = true;
        });
    },
    onTransmitCheck() {
      this.errorMessage = "";
      this.showErrorPopup = false;
      this.onTransmit();
    },
    imgCenter() {
      this.$nextTick(() => {
        const uploaderWrapper = document.querySelector(
          ".van-uploader__wrapper"
        );
        if (uploaderWrapper) {
          uploaderWrapper.style.justifyContent = "center";
          uploaderWrapper.style.alignItems = "center";
          uploaderWrapper.style.webkitJustifyContent = "center";
          uploaderWrapper.style.webkitAlignItems = "center";
        }
      });
    },
    onTryAgainClick() {
      this.failModel = false;
      setTimeout(() => {
        const element = document.querySelector(".van-dropdown-menu__bar");
        element.style.boxShadow = "none";
        const high = document.querySelector(".height60");
        high.style.height = "0px";
      }, 1);
    },
  },
  watch: {
    currentVideoInputDevice: function () {
      this.decodeFromInputVideo();
    },
    decodeResult: function () {
      this.successDecode();
    },
  },
};
</script>

<style scoped lang="scss">
.title-center {
  display: block;
  margin: 0 auto;
}
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
.scan {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ff9900;
  width: 100vw;
  height: 100vh;
  background: #000;
  .video-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    video {
      width: 96vw;
      max-height: 80vh;
    }
    @media (min-width: 500px) {
      video {
        // width: 80vh;
        max-width: 96vw;
        height: 80vh;
      }
    }
  }
  .camera-container {
    margin-top: 5vh;
    width: 80%;
    height: 50px;
    line-height: 44px;
    border-radius: 10px;
    border: 3px solid #ff9900;
    display: flex;
    justify-content: center;
    select {
      width: calc(100% - 80px);
      color: #ff9900;
      background: transparent;
      border: none;
      outline: none;
    }
  }
}
.imgColor {
  border-top: 2px solid #ff0000;
  border-bottom: 2px solid #cbcbcb;
  width: 100%;
  align-items: center;
}
.bottom {
  border-top: 2px solid #ff0000;
}
.transmitButton {
  padding: 24px;
  margin-top: 20px;
}

.parent15 {
  margin-top: 15px;
}
.parent30 {
  margin-top: 30px;
}
.parent50 {
  margin-top: 50px;
}
.parent100 {
  margin-top: 40px;
}
.parentbtn {
  margin-top: 50px;
}

.height60 {
  height: 30px;
}

.my-field {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 6px;
  text-align: right;
  align-items: center;
  padding: 12px;
}
.my-label {
  color: #888888;
  font-size: 16px;
}
.placeholder {
  display: inline-block;
  width: 115px;
  height: 1px;
  vertical-align: middle;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色 */
}
.failTxt {
  text-align: center;
  width: 100%;
  font-size: 22px;
  margin-top: 60px;
  padding-left: 10px;
}
.imgFormat {
  margin: 0px 3% 0px 5%;
}
.my-dropdown-menu {
  width: 95px;
}
.preview-cover img {
  width: 25px;
  height: 25px;
}
</style>
